<template>
  <div class="row">
    <div class="container">
      <Logo/>
      <div class="row">
        <div class="col-lg-12">

          <div class="signupSec" v-show="!isSubmitting">
            <div class="formsec">
              <form
                  action='https://forms.zohopublic.com/virtualoffice9283/form/SignUpForm/formperma/qtRF9UtcylA-wgc52_gXLZsIcFytRVHEKsnEv7sQ2g0/htmlRecords/submit'
                  name='form' @submit="checkForm" ref="form" method='POST'
                  onSubmit='javascript:document.charset="UTF-8";'
                  accept-charset='UTF-8' enctype='multipart/form-data' id="pardot-form">
                <input type="hidden" name="zf_referrer_name" value="">
                <!-- To Track referrals , place the referrer name within the " " in the above hidden input field -->
                <input type="hidden" name="zf_redirect_url" :value="redirectUrl">
                <!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field -->
                <input type="hidden" name="zc_gad" value="">
                <!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM -->
                <input type="hidden" name="utm_source" value=""/>
                <input type="hidden" name="utm_medium" value=""/>
                <input type="hidden" name="utm_campaign" value=""/>
                <input type="hidden" name="utm_term" value=""/>
                <input type="hidden" name="utm_content" value=""/>
                <input type="hidden" name="SingleLine8" :value="upper+'-SAVE'" fieldType=1 maxlength="255"/>
                <input type="hidden" name="SingleLine9" :value="source" fieldType=1 maxlength="255"/>
                <input type="hidden" name="SingleLine13" :value="lower" fieldType=1 maxlength="255"/>
                <input type="hidden" name="SingleLine14" :value="$i18n.locale" fieldType=1 maxlength="255"/>

                <input type="hidden" name="Decimal" :value="this.rogers_list" fieldType=1 maxlength="255"/>
                <input type="hidden" name="Decimal1" :value="this.upFrontTextWithTax" fieldType=1 maxlength="255"/>
                <input type="hidden" name="Decimal2" :value="this.savingsText" fieldType=1 maxlength="255"/>
                <input type="hidden" name="Decimal3" :value="this.upfront" fieldType=1 maxlength="255"/>
                <input type="hidden" name="Address_Country" value="Canada" fieldType=1 maxlength="255"/>

                <PrimaryForm :form="form"/>
                <div class="formRow">
                  <label>{{ $t("first_name") }}
                    <span :style="asterisksColor"> *</span>
                  </label>
                  <input required="required" type="text" name="SingleLine" checktype="c1" v-model="firstName"
                         fieldType=1
                         class="form-control">
                </div>
                <div class="formRow">
                  <label>{{ $t("last_name") }}
                    <span :style="asterisksColor"> *</span>
                  </label>
                  <input required="required" type="text" name="SingleLine5" checktype="c1" v-model="lastName"
                         fieldType=1 class="form-control">
                </div>
                <div class="formRow">
                  <label>{{ $t('email') }}
                    <span :style="asterisksColor"> *</span>
                  </label>
                  <input fieldType=9 type="email" maxlength="255" required="required" name="Email" checktype="c5"
                         value=""/>
                  <small>{{ $t("monthly_invoices_msg") }}</small>
                </div>
                <div class="formRow addressinput">
                  <label>{{ $t('street_address') }}<span :style="asterisksColor"> *</span></label>
                  <input name="Address_AddressLine1" type="text" placeholder="Address line 1" required>
                  <input name="Address_AddressLine2" type="text" placeholder="Address line 2">
                </div>
                <div class="formRow">
                  <label>{{ $t('city') }}
                    <span :style="asterisksColor"> *</span>
                  </label>
                  <input required="required" type="text" maxlength="255" name="Address_City" checktype="c1"/>
                </div>
                <div class="formRow">
                  <label>{{ $t('province') }}
                    <span :style="asterisksColor"> *</span>
                  </label>
                  <input type="text" required="required" maxlength="255" name="Address_Region"
                         checktype="c1"/>
                </div>
                <div class="formRow">
                  <label>{{ $t('postal_code') }}
                    <span :style="asterisksColor"> *</span>
                  </label>
                  <input required="required" type="text" maxlength="255" name="Address_ZipCode"
                         checktype="c1"/>
                  <p><br><small>* SIM card and\or new phones are shipped to this address. <br>*Rogers does not ship to
                    PO
                    Boxes. <br>*A signature is required for delivery</small></p>
                </div>

                <div v-for="(form,idx) in additionalLines" v-bind:key="'additional_'+idx" class="card p-2 mt-3">
                  <AdditionalForm @remove="removeLine" :form="form" :idx="idx+2"/>
                </div>
                <div class="textCol additionaline">
                  <h3>Do you want to transfer additional lines?</h3>
                  <div class="popupbtn" style="cursor: pointer" @click="increaseLines">{{ linesButtonText }}</div>
                </div>


                <div v-if="phoneAvailable" class="formRow">
                  <label>{{ $t("phone_to_purchase") }}
                  </label>
                  <div v-for="(phone,index) in phoneName" :key="index">
                    <input type="hidden" name="SingleLine7" checktype="c1" :value="phone">
                    {{ $t('line') }} {{ index + 1 }}:
                    <i>{{ phone }}</i>
                  </div>

                </div>


                <div class="formRow">
                  <label>{{ $t("summary") }} </label>
                  <textarea name="MultiLine" maxlength="65535" :value="planAndPhoneInfo" ref="summary" @input="resizeSummary" @change="resizeSummary" ></textarea>
                </div>
                <div class="formRow">
                  <label>{{ $t('comments') }} </label>
                  <textarea maxlength="65535" v-model="comments"></textarea>
                </div>

                <ShowData v-for="(data, key) in form.disclaimer.data" :key="key" :data="data"></ShowData>
                <div class="textCol">
                  <input type="submit" style=" width: 100%;outline: none;border: 0px solid;text-align:left;
                  padding-left:12px" id='formsubmit' class="next-btn text-left" title='Submit' value="Submit">
                  <div class="prev-btn" @click="previousStep">{{$t('previous')}}</div>
                </div>
              </form>
            </div>
          </div>

          <div v-show="isSubmitting" style="text-align: center">
            Submitting the form, please wait...
          </div>

        </div>
      </div>
    </div>
    <p class="zf-frmDesc"></p>

  </div>
</template>

<script>
import ShowData from "@/components/ShowData";
import AdditionalForm from "@/components/AdditionalForm";
import PrimaryForm from "@/components/PrimaryForm";
import Logo from "@/components/Logo.vue";

export default {
  components: {
    Logo,
    ShowData,
    AdditionalForm,
    PrimaryForm
  },
  name: 'SignUpForm',
  data() {
    return {
      isSubmitting: false,
      firstName: '',
      lastName: '',
      comments: '',
      additionalLineCount: 0,
      additionalLines: []
    };
  },
  created() {
    this.bindAdditionalLines();
  },
  methods: {
    resizeSummary() {
      setTimeout(() => {
        this.$refs.summary.style.height = 'auto';
        this.$refs.summary.style.height = this.$refs.summary.scrollHeight + 'px';
      }, 200);

    },
    previousStep() {
      this.$emit('previousStep');
    },
    bindAdditionalLines() {
      this.form.additionalInfo = this.planAndPhoneInfo;
      //replace all \n with <br>
      this.form.additionalInfo = this.form.additionalInfo.replace(/\n/g, '<br>');
    },
    removeLine(index) {
      this.additionalLines.splice(index, 1);
    },
    cloneCurrentForm() {
      let form = JSON.parse(JSON.stringify(this.form));
      form.currentMobilePhoneNumber = '';
      return form;

    },
    increaseLines() {
      //clone this form to new form
      this.additionalLines.push(this.cloneCurrentForm());
    },
    checkForm(e) {

      if (!this.form.selectedPlan) {
        document.getElementById("main_form").scrollIntoView();
        document.getElementById("selected_plan").reportValidity();
        e.preventDefault();
      }

      if (this.isNewPhone && !this.form.selectedPhone) {
        document.getElementById("main_form").scrollIntoView();
        document.getElementById("selected_phone").reportValidity();
        e.preventDefault();
      }

      if (this.isNewPhone && this.form.selectedColor === '') {
        document.getElementById("main_form").scrollIntoView();
        document.getElementById("phone_color").reportValidity();
        e.preventDefault();
      }
      this.isSubmitting = true;

      return true;

    },
    formatMoney(value) {
      return Number(value).toFixed(2).toLocaleString();
    },
    getPhoneInfo(form) {
      // if (!this.additionalLines[index].selectedPhone) return 'No Phone selected';
      //if color selected add color to name
      let phoneData = this.form.phones[form.selectedPhone - 1];
      let phoneName = phoneData.phone;
      if (form.selectedColor) {
        phoneName += ' - ' + form.selectedColor;
      }
      return phoneName;
    },
    getPlanDescription(form) {
      if (!form.selectedPlan) return this.$t('no_plan_selected');
      let selectedPlan = form.plans[form.selectedPlan - 1];
      if (form.planType === this.$t('byod')) {
        if (this.isRogersCustomer()) {
          let planCost = form.computedData.plan_cost
          return  planCost['valueWithoutTax'] + ' ' + this.$t('monthly_plus_tax') + ' (' + this.$t('byod') + ') - ' + selectedPlan.description;
        }
        return '$' + this.formatMoney(selectedPlan.BYOD) + ' ' + this.$t('monthly_plus_tax') + ' (' + this.$t('byod') + ') - ' + selectedPlan.description;
      } else {
        if (this.isRogersCustomer()) {
          let planCost = form.computedData.plan_cost
          return  planCost['rogersRate'] + ' ' + this.$t('monthly_plus_tax') + ' - ' + ' ' + selectedPlan.description;
        }
        return '$' + this.formatMoney(selectedPlan.with_device) + ' ' + this.$t('monthly_plus_tax') + ' - ' + ' ' + selectedPlan.description;
      }
    },
    isRogersCustomer() {
      return this.form.rogerCustomers === this.$t('yes');
    },
    generateLine(line, form, name) {
      let planAndPhone = '';
      planAndPhone += line + name + ' - ' + form.currentMobilePhoneNumber + '\n';
      if (this.isRogersCustomer()) {
        planAndPhone += line + form.currentProvider;
        if (form.integrationId) {
          //if form.integrationId starts with I
          if (form.integrationId[0] === 'I') {
            planAndPhone += '-IID-' + form.integrationId;
          } else {
            planAndPhone += '-IID-I' + form.integrationId;
          }
        }
        planAndPhone += '\n';
      } else {
        planAndPhone += line + form.currentProviderValue + '\n';
      }

      if (line === '1: ') {
        //if provider is none add "1: Security Deposit - $300"
        if (form.currentProvider === 'None') {
          planAndPhone += line + this.$t('security_deposit') + ' - $300' + '\n';
        }
      }

      planAndPhone += line + this.getPlanDescription(form) + '\n';
      // Get plan_cost from computedData
      let planCost = form.computedData.plan_cost;

      // Check if a phone is selected
      if (form.selectedPhone) {
        const phone = form.phones[form.selectedPhone - 1]; // Get the selected phone's data
        const rogersListPerMonth = phone.rogers_list / 36; // Calculate monthly phone cost

      // Append rogers_list/36 - phoneDisc to the plan cost line
        if (form.phoneDisc > 0) {
          planAndPhone += line + planCost['title'] + ' - ' + planCost['value'] + ' includes Phone financing - $' + this.formatMoney(rogersListPerMonth - form.phoneDisc) + ': ($' + this.formatMoney(rogersListPerMonth) + ' - $' + form.phoneDisc + '- Phone Bundle Discount) over 36 months\n';
        }else {
          planAndPhone += line + planCost['title'] + ' - ' + planCost['value'] + ' (includes Phone financing - $' + this.formatMoney(rogersListPerMonth) + ' over 36 months)\n';
        }

      } else {
  // No phone selected, keep the line the same
      planAndPhone += line + planCost['title'] + ' - ' + planCost['value'] + '\n';
      }

//      let discountValue = "0.00";
//      let discount = form.computedData.discount;

//      if (discount < 0) {
//        discountValue = discount * -1;
//      }

      if (form.selectedPhone) {
        let phone = this.form.phones[form.selectedPhone - 1];
        let list = phone.rogers_list;
        list = Number(list).toFixed(2);
        let upfront = phone.upfront;
        upfront = Number(upfront).toFixed(2)
        planAndPhone += line + this.getPhoneInfo(form) + ' - (Device value: $'+ this.formatMoney(list) +' + Tax)\n';
//        planAndPhone += line + this.$t('phone_upfront_cost') + ' - ' + form.computedData.upfront_without_tax + " +" + this.$t('tax');
          let phoneUpfront = upfront - 675 < 0 ? 0 : upfront - 675;
        planAndPhone += line + this.$t('phone_upfront_cost') + ' - $'+ this.formatMoney(phoneUpfront) + " +" + this.$t('tax');
        planAndPhone += '\n';
      }

      if (form.selectedPhone) {
        //let phone = this.form.phones[form.selectedPhone - 1];
        //1130.00-0.00-234.00-3.42 1130=listprice, 0=discount, 234=upfront, 3.42=tax
//        let list = phone.rogers_list;
//        list = Number(list).toFixed(2);
//        let phoneUpfront = phone.upfront;
//        phoneUpfront = Number(phoneUpfront).toFixed(2)


        planAndPhone += '0.00-0.00-0.00-0.00' + '\n';
      } else {
        planAndPhone += '0.00-0.00-0.00-0.00' + '\n';
      }

      planAndPhone += '\n';
      return planAndPhone;
    }

  },
  computed: {
    changeData() {
      return [this.form.selectedPlan, this.form.selectedPhone, this.form.selectedProvince, this.form.selectedColor,
        this.form.rogerCustomers, this.form.planType, this.form.months, this.additionalLines, this.form.currentProvider, this.comments];
    },
    linesButtonText() {
      return this.$t('additional_lines');
    },
    billingName() {
      //firstname + lastname
      return this.firstName + ' ' + this.lastName;
    },
    asterisksColor() {
      return {
        color: 'red',
      }
    },
    redirectUrl() {
      if (this.isNewPhone) {
        return 'https://www.vestanetworks.com/site/' + this.rurlnphone + '/' + this.lower;
      } else {
        return 'https://www.vestanetworks.com/site/' + this.rurlbyod + '/' + this.lower;
      }
    },
    phoneAvailable() {
      if (this.additionalLines.length > 0) {
        for (let i = 0; i < this.additionalLines.length; i++) {
          if (this.additionalLines[i].selectedPhone) {
            return true;
          }
        }
      }
      return this.isNewPhone;
    },
    phoneName() {
      if (!this.phoneAvailable) return this.$t('no_phone_selected');
      let phoneArray = [];
      if (!this.form.selectedPhone) {
        phoneArray.push(this.form.planType === this.$t('byod') ? this.$t('byod') : this.$t('no_phone_selected'));
      } else {
        phoneArray.push(this.getPhoneInfo(this.form));
      }

      //if color selected add color to name
      for (let i = 0; i < this.additionalLines.length; i++) {
        let form = this.additionalLines[i];
        let selectedPhone = form.selectedPhone;
        if (!selectedPhone) {
          phoneArray.push(form.planType === this.$t('byod') ? this.$t('byod') : this.$t('no_phone_selected'));
        } else {
          phoneArray.push(this.getPhoneInfo(form));
        }
      }
      return phoneArray;
    },
    lower() {
      return document.getElementById('lower').value;
    },
    upper() {
      return document.getElementById('upper').value;
    },
    source() {
      return document.getElementById('source').value;
    },
    rurlbyod() {
      return document.getElementById('rurlbyod').value;
    },
    rurlnphone() {
      return document.getElementById('rurlnphone').value;
    },
    isBYOD() {
      return this.form.planType === this.$t('byod');
    },
    rogers_list() {
      if (this.isBYOD) {
        return '0.00';
      }
      if (!this.form.selectedPhone) return '0';
      let selectedPhone = this.form.phones[this.form.selectedPhone - 1];
      return selectedPhone.rogers_list;
    },
    upfront() {
      if (this.isBYOD) {
        return '0.00';
      }
      if (!this.form.selectedPhone) return '0.00';
      let selectedPhone = this.form.phones[this.form.selectedPhone - 1];
      return selectedPhone.upfront;
    },
    upFrontTextWithTax() {
      if (this.isBYOD) {
        return '0.00';
      }
      let upfrontArray = this.form.pdfData.find(item => item.id === 18);
      let value = upfrontArray.value;
      if (!value) return '0.00';
      //replace $ with empty
      value = value.replace('$', '');
      //replace  for the phone with empty
      value = value.replace(' ' + this.$t('for_the_phone'), '');
      //replace , with empty
      value = value.replace(',', '');
      value = parseFloat(value);
      if (value < 0) {
        value = value * -1;
      }
      return value;
    },
    savingsText() {
      if (this.isBYOD) {
        return '0.00';
      }
      let savingsArray = this.form.pdfData.find(item => item.id === 25);
      let value = savingsArray.value
      if (!value) return '0.00';
      //You Save Monthly $(5.73) for 36 months
      value = value.replace('$(', '');
      value = value.replace(') for 36 months', '');
      //replace , with empty
      value = value.replace(',', '');
      value = parseFloat(value);
      if (value < 0) {
        value = value * -1;
      }
      if (isNaN(value)) {
        return '0.00';
      }
      return value;
    },
    planAndPhoneInfo() {
      let planAndPhone = '';
      planAndPhone += this.generateLine('1: ', this.form, this.billingName);
      for (let i = 0; i < this.additionalLines.length; i++) {
        let form = this.additionalLines[i];
        let line = (i + 2) + ': ';
        planAndPhone += this.generateLine(line, form, form.name);

      }

      if (this.comments) {
        planAndPhone += 'Comments: ' + this.comments + '\n';
      }
      //limit to 100 characters
      if (planAndPhone.length > 2000) {
        planAndPhone = planAndPhone.substring(0, 2000);
      }
      return planAndPhone;

    },
    isNewPhone() {
      return this.form.planType === this.$t('new_phone');
    }
  },
  props: {
    form: Object
  },
  watch: {
    changeData: {
      handler: function () {
        this.bindAdditionalLines();
      },
      deep: true
    },
    'form.rogerCustomers': function (newValue) {
      for (let i = 0; i < this.additionalLines.length; i++) {
        this.additionalLines[i].rogerCustomers = newValue;
      }
    },
    'form.selectedProvince': function (newValue) {
      for (let i = 0; i < this.additionalLines.length; i++) {
        this.additionalLines[i].selectedProvince = newValue;
      }
    },
    planAndPhoneInfo: function () {
      this.resizeSummary();
    },
  }
}
</script>
